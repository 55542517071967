#rodape {
  background: #363636;
  color: #fff;
}

.tamanho-13 {
  font-size: 13px;
}

.tamanho-14 {
  font-size: 14px;
}

.bd-contact {
  border-left: 1px solid #ffffff;
  box-sizing: border-box;
}

.footer-title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.fonte-cor-branca,
.fonte-cor-branca:hover {
  color: #fff;
}
.figure-creative-commons {
  margin-top: 1.2rem;
}

.links-menu {
  border-bottom: 1px solid #dee2e6;

  @media (max-width: 576px) {
    border-bottom: 0 none !important;
  }

  .nav-link {
    border: 0 none !important;

    &:hover {
      border: 0 none;
      padding: 0.5rem 1rem;
    }
  }

  button{
    background-color: transparent;
  }


}

#menu-principal{
  @media(max-width: 992px) {
    flex-basis: 100%;
  }
}

// Cores

$corBranco: #ffffff;

$corCinzaClaro: #f3f3f3;
$corCinzaClaroSelect: #a4a4a4;
$corCinzaEscuro: #c4c4c4;
$corCinzaHome: #5c6f78;
$corCinzaBuscaOff: #ced4da;
$corCinzaRodape: #5a5a5a;
$corCinzaToggle: #565656;

$corPretoPortal: #42474a;

$corVerdeClaro: #afd367;
$corVerdeEscuro: #4e9c45;
$corVerdeBotaoHome: #afce68;

$corAzulFiltro: #00a6e2;
$corAzulBackgroundTable: #d7f0ff;
$corAzulBuscaOn: #80bdff;
$corAzulPortal: #196cbd;

// Fontes

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");

@mixin roboto-sans {
  font-family: "Roboto", sans-serif !important;
}
